import React from "react";

import "./footerSign.css";

const FooterSign = (props) => {
  return (
    <div className="Footer-SIGN">
      <div className="footer-sign_items" id="SIGN">
        <div className="sign_item">2020 - Developed by VAHID GHODSI</div>
        <div className="sign_item sign_website">
          <a href="http://cv.vahidghodsi.com" target="_blank">
            WWW.CV.VAHIDGHODSI.COM
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSign;
