import React, { useState } from "react";

const SlideShowSlideHolder = (props) => {
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startTime, setStartTime] = useState();

  let threshold = 50, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 400; // maximum time allowed to travel that distance

  const touchStart = (e) => {
    // e.preventDefault();
    let touchobj = e.changedTouches[0];
    // swipedir = "none";
    // dist = 0;
    setStartX(touchobj.pageX);
    setStartY(touchobj.pageY);
    setStartTime(new Date().getTime()); 
  };

  const touchMove = (e) => {
    // e.preventDefault(); 
  };

  const touchEnd = (e) => {
    e.preventDefault();
    let swipedir;
    let touchobj = e.changedTouches[0];
    let distX = touchobj.pageX - startX; 
    let distY = touchobj.pageY - startY; 
    let elapsedTime = new Date().getTime() - startTime; 
    if (elapsedTime <= allowedTime) {
      if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
        swipedir = distX < 0 ? "left" : "right"; 
      } else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
        swipedir = distY < 0 ? "up" : "down"; 
      }
    }
    props.swipeHandler(swipedir);
  };

  return (
    <div
      className="slide-holder"
      onTouchStart={(e) => touchStart(e)}
      onTouchMove={(e) => touchMove(e)}
      onTouchEnd={(e) => touchEnd(e)}
    >
      <img src={props.slideSrc}/>
    </div>
  );
};

export default SlideShowSlideHolder;
