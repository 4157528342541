const locationParser = (location) => {
  let search = [];
  if (location.search.length !== 0) {
    search = location.search.substring(1).split("&");
    search.forEach((searchItem, index) => {
      search[index] = searchItem.split("=");
    });
  }

  return {
    pathname: location.pathname,
    hash: location.hash.substring(1),
    search: search,
  };
};

export default locationParser;
