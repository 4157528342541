import React, { useState } from "react";

import SlideShowSlideHolder from "./SlideShowSlideHolder";
import Img from "../ui/Img";

import "./slideShow.css";

const SlideShow = (props) => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  let projectFolder = props.project.id + "_" + props.project.title;

  const clickHandler = (e, slideIndex) => {
    // console.log(e.target);
    // e.preventDefault();
    e.stopPropagation();
    console.log(slideIndex);
    setSelectedSlide(slideIndex);
  };

  const prevSlide = () => {
    if (selectedSlide > 0) {
      setSelectedSlide(selectedSlide - 1);
    }
  };

  const nextSlide = () => {
    if (selectedSlide < props.slidesQty - 1) {
      setSelectedSlide(selectedSlide + 1);
    }
  };

  const swipeHandler = (direction) => {
    console.log("[swipe]:", direction);

    switch (direction) {
      case "right":
        prevSlide();
        break;

      case "left":
        nextSlide();
        break;

      default:
        break;
    }
  };

  let tumbnails = [];
  for (let i = 0; i < props.slidesQty; i++) {
    tumbnails.push(i);
  }

  tumbnails = tumbnails.map((index) => {
    let tmbCls = index === selectedSlide ? "slide-tumbnail slide-current" : "slide-tumbnail";
    return (
      // <li key={index} className="slide-tumbnail" onClick={(e) => clickHandler(e, index)}>
      <li key={index} className={tmbCls} onClick={(e) => setSelectedSlide(index)}>
        <Img src={`assets/img-projects/${projectFolder}/${index + 1}.jpg`} alt={props.project.title} />
      </li>
    );
  });

  return (
    <div className="Slideshow">
      <SlideShowSlideHolder
        swipeHandler={(direction) => swipeHandler(direction)}
        slideSrc={`assets/img-projects/${projectFolder}/${selectedSlide + 1}.jpg`}
      />
      <div className="slide-control">
        <button disabled={selectedSlide <= 0} className="slide-control__previous" onClick={(e) => prevSlide(e)}>
          &#60;
        </button>
        <ul className="slide-control__tumbnails"> {tumbnails} </ul>
        <button
          disabled={selectedSlide >= props.slidesQty -1 }
          className="slide-control__next"
          onClick={(e) => nextSlide(e)}
        >
          &#62;
        </button>
      </div>
    </div>
  );
};

export default SlideShow;
