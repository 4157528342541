import React, { useState } from "react";

import Project from "./project";

import "./projects.css";

const Projects = props => {
  const [selectedProject, setSelectedProject] = useState("");

  const clickHandler = project => {
    // console.log("[PROJECTS]: ",project);
    setSelectedProject(project);
  };

  // console.log(props);
  let projects = <div> NO PROJECT FOUND </div>;
  let projectsList = props.projects;
  // console.log(projectsList);
  if (projectsList.length > 0) {
    projects = projectsList.map(project => (
      <Project
        key={project.id}
        project={project}
        click={prId => clickHandler(prId)}
        selectedProject={selectedProject}
      />
    ));
  }
  
  let consProjects = <div> NO PROJECT FOUND </div>;
  let consProjectsList = props.consultancyProjects;
  // console.log(projectsList);
  if (consProjectsList.length > 0) {
    consProjects = consProjectsList.map(project => (
      <Project
        key={project.id}
        project={project}
        click={prId => clickHandler(prId)}
        selectedProject={selectedProject}
      />
    ));
  }

  return (
    <section className="Projects Mas-layout">
      {/* <div className="projects__message">{this.state.message}</div>*/}
      <ul className="projects__items">{projects}</ul>
      <div className="projects__section-head">
        <div className="projects-section__title">Workspace</div>
        <p>
          Babak Toosipour Architecture consults on efficient, productive and flexible work environment design solutions
          that can be used for multiple activities in the short term and a variety of possible long term.
        </p>
      </div>
      <ul className="projects__items">{consProjects}</ul>
    </section>
  );
};

export default Projects;
