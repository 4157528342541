import React from "react";
import { NavLink } from "react-router-dom";

import HeaderNav from "./HeaderNav";

import "./header.css";

const Header = (props) => {
  let headerCls = "HEADER";
  if (props.appMenu.active) {
    headerCls = "HEADER menu-open";
  }

  return (
    <header className={headerCls}>
      <div className="header-holder Mas-layout">
        <div className="header-holder__menu-grip" onClick={() => props.appMenu.toggle()}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="header__logo">
          {/* <div className="header-logo__sign">
            <img src="assets/img/BTA Logo.png" alt="Babak Toosipour Architects" />
          </div> */}
          <NavLink to={"/home"} exact className="header-logo__sign">
            <img src="assets/img/BTA Logo.png" alt="Babak Toosipour Architects" />
          </NavLink>
          <div className="header-logo__text">
            Babak Toosipour <span>Design</span>
          </div>
        </div>
        <HeaderNav linkClick={() => props.appMenu.toggle()} />
      </div>
    </header>
  );
};

export default Header;
