import React from "react";

import "./icon.css";

const Icons = (props) => {
  let icon = null;

  switch (props.icon) {
    case "instagram":
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 550">
          <g className="cls-icons">
            <path d="m 373.406 0 h -234.813 c -76.4219 0 -138.594 62.1719 -138.594 138.594 v 234.816 c 0 76.418 62.1719 138.59 138.594 138.59 h 234.816 c 76.418 0 138.59 -62.1719 138.59 -138.59 v -234.816 c 0 -76.4219 -62.1719 -138.594 -138.594 -138.594 Z m -117.406 395.996 c -77.1953 0 -139.996 -62.8008 -139.996 -139.996 s 62.8008 -139.996 139.996 -139.996 s 139.996 62.8008 139.996 139.996 s -62.8008 139.996 -139.996 139.996 Z m 143.344 -246.977 c -22.8125 0 -41.3672 -18.5547 -41.3672 -41.3672 s 18.5547 -41.3711 41.3672 -41.3711 s 41.3711 18.5586 41.3711 41.3711 s -18.5586 41.3672 -41.3711 41.3672 Z m 0 0" />
            <path d="m 256 146.02 c -60.6406 0 -109.98 49.3359 -109.98 109.98 c 0 60.6406 49.3398 109.98 109.98 109.98 c 60.6445 0 109.98 -49.3398 109.98 -109.98 c 0 -60.6445 -49.3359 -109.98 -109.98 -109.98 Z m 0 0" />
          </g>
        </svg>
      );
      break;

    case "linkedin":
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            className="cls-icons"
            d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
          />
        </svg>
      );
      break;

    case "facebook":
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            className="cls-icons"
            d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"
          />
        </svg>
      );
      break;

    case "youtube":
      icon = (
        <svg id="social-youtube" viewBox="5 2 43 43">
          <g className="cls-icons">
            <path d="M47.48 13.21s-.46-3.3-1.9-4.74c-1.82-1.92-3.86-1.92-4.8-2.04C34.08 5.94 24 6 24 6s-10.04-.06-16.74.42c-.92.12-2.96.12-4.78 2.04C1.04 9.9.56 13.2.56 13.2S.08 17.09 0 20.96v4.05c.08 3.88.56 7.75.56 7.75s.48 3.3 1.92 4.76c1.82 1.9 4.2 1.84 5.28 2.04 3.76.36 15.82.44 16.24.44 0 0 10.1.02 16.8-.46.92-.12 2.96-.12 4.78-2.04 1.44-1.44 1.92-4.74 1.92-4.74s.48-3.88.5-7.75v-4.05c-.04-3.87-.52-7.75-.52-7.75zM19.14 30V15.98L32 23.26 19.14 30z"></path>
          </g>
        </svg>
      );
      break;

    default:
      break;
  }

  return (
    <a className="footer-icons__item" href={props.link || ""} target="_blank">
      {icon}
    </a>
  );
};

export default Icons;
