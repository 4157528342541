import React, { useState } from "react";

import SlideShow from "../../../components/slideShow/SlideShow";
import Img from "../../../components/ui/Img";

import "./project.css";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Project = (props) => {
  const [descExpanded, setDescExpanded] = useState(false);
  const [teamExpanded, setTeamExpanded] = useState(false);

  // console.log(props.project);
  let projectFolder = props.project.id + "_" + props.project.title;
  let projectDate = new Date(props.project.date);

  let projectCls = "projects__project";
  let projectDesc = null;
  let slideShow = null;
  let expandedDesc = null;
  let descMoreBtn = null;

  const body = document.getElementById("body");
  let scrollY = 0;

  // const expandedExit = (Ycord, cb) => {
  //   document.getElementById("body").classList.remove("nonScroll-mobile");
  //   // setEnlargedImg(false);
  //   cb()
  // };

  if (props.project.id === props.selectedProject) {
    projectCls = "projects__project selected";
    slideShow = <SlideShow project={props.project} slidesQty={props.project.slidesQty} />;

    let descLength = 0;
    if (props.project.description) {
      if (Array.isArray(props.project.description)) {
        descLength = props.project.description.join().length;
        projectDesc = props.project.description.map((line, index) => <p key={index}>{line}</p>);
      } else {
        descLength = props.project.description.length;
        projectDesc = props.project.description;
      }
    }

    if (descLength > 400) {
      descMoreBtn = (
        <div
          className="option-btn"
          onClick={() => {
            body.classList.add("nonScroll-mobile");
            setDescExpanded(!descExpanded);
          }}
        >
          more
        </div>
      );
    }

    expandedDesc = (
      <div
        className={descExpanded ? "project__expanded-desc desc-expanded" : "project__expanded-desc"}
        onClick={() => {
          body.classList.remove("nonScroll-mobile");
          setDescExpanded(!descExpanded);
        }}
      >
        <div
          className="option-btn"
          onClick={() => {
            body.classList.remove("nonScroll-mobile");
            setDescExpanded(!descExpanded);
          }}
        >
          close
        </div>
        {projectDesc}
      </div>
    );

    projectDesc = (
      <div className={descExpanded ? "project-info__desc desc-expanded" : "project-info__desc"}>
        {props.project.description ? (
          <div
            className="option-btn desc__about-project"
            onClick={() => {
              body.classList.add("nonScroll-mobile");
              setDescExpanded(!descExpanded);
            }}
          >
            About Project
          </div>
        ) : null}
        <div className="desc__text">{projectDesc}</div>
        {descMoreBtn}
      </div>
    );
  }

  let client = null;
  if (props.project.client) {
    client = (
      <dl className="project-info__item">
        <dt>Client</dt>
        <dd> {props.project.client} </dd>
      </dl>
    );
  }

  let archFirm = null;
  if (props.project.architectureFirm) {
    archFirm = (
      <dl className="project-info__item">
        <dt>Arch. Firm</dt>
        <dd> {props.project.architectureFirm} </dd>
      </dl>
    );
  }

  let team = null;
  if (props.project.team) {
    // console.log(Object.keys(props.project.team).length);
    team = Object.keys(props.project.team).map((item) => (
      <dl key={item} className="project-info__item">
        <dt>{item}</dt>
        <dd> {props.project.team[item]} </dd>
      </dl>
    ));
  }

  let projectTeam = null;
  let expandedTeam = null;
  if (archFirm || client || team) {
    projectTeam = (
      <div
        className="option-btn team__show-team"
        onClick={() => {
          body.classList.add("nonScroll-mobile");
          setTeamExpanded(!teamExpanded);
        }}
      >
        Project's Team
      </div>
    );
    expandedTeam = (
      <div
        className={teamExpanded ? "project__expanded-team team-expanded" : "project__expanded-team"}
        onClick={() => {
          body.classList.remove("nonScroll-mobile");
          setTeamExpanded(!teamExpanded);
        }}
      >
        <div
          className="option-btn"
          onClick={() => {
            body.classList.remove("nonScroll-mobile");
            setTeamExpanded(!teamExpanded);
          }}
        >
          close
        </div>
        {archFirm}
        {client}
        {team}
      </div>
    );
  }

  return (
    <li className={projectCls} onClick={() => props.click(props.project.id)}>
      <div className="project__background">
        <Img src={`assets/img-projects/${projectFolder}/1.jpg`} alt={props.project.title} />
      </div>
      <div className="project__head"> {props.project.title} </div>
      <div className="project__body">
        {expandedDesc}
        {expandedTeam}
        <div className="project-body__slideshow">{slideShow}</div>
        <ul className="project-body__info">
          <div className="project-info__title">{props.project.location + " - " + props.project.status}</div>
          {projectDesc}
          <div className="project-info__items">
            <dl className="project-info__item">
              <dt>Date</dt>
              <dd> {monthNames[projectDate.getMonth()] + " " + projectDate.getFullYear()} </dd>
            </dl>
            <dl className="project-info__item">
              <dt>Type</dt>
              <dd> {props.project.category} </dd>
            </dl>
            <dl className="project-info__item">
              <dt>Architect</dt>
              <dd> {props.project.architect} </dd>
            </dl>
            <div className="project-info__team">
              {projectTeam}
              {/* <div className="project-team__items">
                {archFirm}
                {client}
                {team}
              </div> */}
            </div>
          </div>
        </ul>
      </div>
    </li>
  );
};

export default Project;
