import React , {useState} from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Home from "../../views/home/Home";
import Projects from "../../views/projects/Projects";
// import Consultancy from "../../views/projects/Consultancy";
import Practice from "../../views/practice/Practice";
import About from "../../views/about/About";
import Contact from "../../views/contact/Contact";

import locationParser from "../../../util/locationParser";
import "./main.css";

const Main = (props) => {
  const [selectedElement, setSelectedElement] = useState("");

  let defaultPath = "/home";
  let pathHash = locationParser(window.location).hash;
  // console.log(locationParser(window.location));
  
  if (pathHash.length !== 0 && selectedElement !== pathHash) {
  setSelectedElement(pathHash);
  }
  let pathSearch = locationParser(window.location).search;
  if (pathSearch.length > 0) {
    pathSearch = pathSearch.find((item) => item[0] === "enterpath");
    if (pathSearch) {
      defaultPath = pathSearch[1];
    }
  }

  return (
    <main className={props.appMenu.active? "MAIN menu-open" : "MAIN"}>
      <Switch>
        <Route path="/home" exact render={(routeProps) => <Home {...routeProps} {...props} />} />
        <Route path="/projects" exact render={(routeProps) => <Projects {...routeProps} {...props} />} />
        <Route path="/practice" exact component={Practice} />
        <Route path="/about" exact component={About} />
        {/* <Route path="/contacts" exact component={Contact} /> */}
        <Route path="/contacts" exact render={(routeProps) => <Contact {...routeProps} {...props} />} />
        <Redirect to={defaultPath} />
      </Switch>
    </main>
  );
};

export default Main;
