import React from "react";

import Header from "./containers/layout/header/Header";
import Main from "./containers/layout/main/Main";
import Footer from "./containers/layout/footer/Footer";

import "./App.css";

const App = (props) => {
  let rootStyle = document.documentElement.style;
  rootStyle.setProperty("--theme_color-1", props.themeColors.themeColor1);
  rootStyle.setProperty("--theme_color-2", props.themeColors.themeColor2);
  rootStyle.setProperty("--theme_color-dark-1", props.themeColors.themeColorDark1);
  rootStyle.setProperty("--theme_color-dark-2", props.themeColors.themeColorDark2);
  rootStyle.setProperty("--theme_font-color-1", props.themeColors.themeFontColor1);
  rootStyle.setProperty("--theme_font-color-2", props.themeColors.themeFontColor2);
  rootStyle.setProperty("--home-color-filter", "none");
  rootStyle.setProperty("--home_color-1", props.themeColors.themeColor1);
  rootStyle.setProperty("--home_color-2", props.themeColors.themeColor2);
  // console.log("[]:", rootStyle);

  return (
    <>
      <Header {...props} />
      <Main {...props} />
      <Footer {...props} />
    </>
  );
}

export default App;
