import React from "react";

import PracticeItem from "./PracticeItem";

import "./practice.css";

const Practice = (props) => {
  return (
    <div className="Expertise Mas-layout2">
      <div className="expertise__explanation">
        <div className="expertise-exp_item">
          <div className="title"> Collaboration </div>
          <div className="descirption">
            <p>
              The practice collaborated with international firms around the world and work globally across the projects.
              This intercontinental network system allows infinite flexibility and capacity to undertake a wide variety
              of projects in different scale and complexity by using the knowledge and experiences of united
              multicultural team to develop innovative strategies with worldwide quality.
            </p>
          </div>
        </div>
        <div className="expertise-exp_item">
          <div className="title"> Sustainability and Environment </div>
          <div className="descirption">
            <p>
              The sustainability and energy efficient architecture has emphasized the practice work since early days due
              to importance of the climate change and challenges to environment. Buildings and cities should be designed
              to minimize pollution and carbon emissions not only using renewable energy resources but designing energy
              efficient building systems, urban organization and intelligent use of the world limited natural resources,
              materials and construction approaches with innovative strategies to integrate with both proven traditional
              methods and modern technologies.
            </p>
          </div>
        </div>
      </div>
      <ul className="expertise__fields">
        <PracticeItem title="Architecture">
          <p>
            Every project begins with studying the elaborations and potentials of the project and all decisions are
            based on research and explorations with understanding of the cultural and social life of people and cities
            that continue envision the future of the society and environment. Harmonious proportion, rationally
            integrated structural systems and scale are fundamental considerations in BTA’s design approach by using
            coordinated systems and futuristic strategies that are informed by technology in both production process and
            construction.
          </p>
        </PracticeItem>
        <PracticeItem title="Urban">
          <p>
            BTA design spaces that are comprehensive to society and environment by a desire to connect people and places
            that can adjust through the generations in the future. Green infrastructure, storing storm water, shading
            and insulating buildings and alleviating climate change plays a critical role in the design strategy.
          </p>
        </PracticeItem>
        <PracticeItem title="Interior">
          <p>
            The aim is to create interiors that are both functional and delicate with a sense of space and balancing a
            building’s outward articulation and solution’s to achieve an understanding of people’s needs and the use of
            the space.
          </p>
        </PracticeItem>
        <PracticeItem title="Furniture">
          <p>
            BTA in collaboration with Babak Furniture, design and manufacture various types of Furniture and Lighting
            with high quality materials by using craftsmanship and modern technology in the manufacturing process and
            the right balance between form and function together with stylish design.
          </p>
        </PracticeItem>
        <PracticeItem title="Structural Engineering">
          <p>
            The practice interact with national and international engineering consultants where the knowledge of locals
            may be beneficial and work on projects from the idea through to completion together with the architecture
            team.
          </p>
        </PracticeItem>
        <PracticeItem title="Construction Management">
          <p>
            BTA plays a key role throughout the whole design and construction phases, from programming and procurement
            consultancies to document control and risk management by working together with the builders and contractors
            to review designs, details and construction methods simultaneously.
          </p>
        </PracticeItem>
        <PracticeItem title="Photography">
          <p>Professional architectural photography and filming based on the concept of the project with a scenario.</p>
        </PracticeItem>
        <PracticeItem title="Reasearch">
          <p>
            The practice is research oriented and forward thinking based to solve building and enviromental challenges
            in a platform with creativity and invention. BTA Focus on sustainability, energy, technology, software and
            artificial intelligence aim to develop design tools, production processes and construction methods for
            designers, manufacturers and builders of small and large scale. BTA is now working on technologies for smart
            buildings with artificial intelligence systems, robotic and modular construction methods, enviromental
            friendly high tech materials and more.
          </p>
        </PracticeItem>

        {/* <PracticeItem title="Architecture">
          Architecture, Interior, Landscape, Urban, Communications, Visualisation, Modelmaking
        </PracticeItem>
        <PracticeItem title="Engineering">Structural, Mechanical, Electrical, Environmental</PracticeItem>
        <PracticeItem title="Industrial Design">Furniture, Lighting</PracticeItem>
        <PracticeItem title="Management">Project and Construction, Procurement</PracticeItem>
        <PracticeItem title="Research">Technology, Energy, Sustainability, Material</PracticeItem>
        <PracticeItem title="Photography">Architectural</PracticeItem> */}
      </ul>
    </div>
  );
};

export default Practice;
