import React from "react";
import { NavLink } from "react-router-dom";

const HeaderNavItem = (props) => {
  return (
    <li className="header-nav__item">
      <NavLink to={props.pathname} exact activeClassName="nav-item-active" onClick={() => props.linkClick()}>
        {props.title}
        {props.children ? <div>{props.children} </div> : null}
      </NavLink>
    </li>
  );
};

export default HeaderNavItem;
