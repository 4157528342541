import React from "react";

import FooterSign from "./FooterSign";
import Icon from "../../../components/graphics/Icon";

import "./footer.css";

const Footer = (props) => {
  const icons = props.socialNetworks.map((account) => (
    <Icon key={account.platform} icon={account.platform} link={account.link} />
  ));

  return (
    <footer className={props.appMenu.active ? "FOOTER menu-open" : "FOOTER"}>
      <div className="footer-holder Mas-layout">
        <div className="footer__icons">
          {icons}
          {/* <Icon icon="instagram" link="https://instagram.com/toosipour.architecture?igshid=1j0q24tsygz45"/>
          <Icon icon="linkedin" link="https://www.linkedin.com/in/babak-toosipour-86431a151/"/>
          <Icon icon="facebook" link="https://www.facebook.com/toosipour.architecture"/>
          <Icon icon="youtube" link="https://babaktoosipour.com"/> */}
        </div>
        <FooterSign />
      </div>
    </footer>
  );
};

export default Footer;
