import React, { useState, useEffect } from "react";

import Img from "../../../components/ui/Img";

import "./home.css";

const Home = (props) => {
  console.log(props.homeSlides);
  const [activeImg, setActiveImg] = useState(0);
  const homeSlides = props.homeSlides;
  let backImgItems = null;
  let homeControl = null;
  let controlItems = null;
  let backImgDesc = null;

  if (homeSlides.length > 0) {
    // console.log("[Inside Home if check]:", activeImg, " of " , homeSlides.length);
    if (activeImg >= homeSlides.length) {
      setActiveImg(0);
      /* } else if (!props.appMenu.active) {  
      this was to stop the slidewhow when menu is open, but any click on maneu toggles this var and stops the home any second click */
    } else {
      backImgItems = homeSlides.map((slide, index) => (
        <div
          key={index}
          className={
            index === activeImg
              ? "home-background__item active"
              : "home-background__item"
          }
        >
          <Img src={`assets/img-home/${slide.src}`} alt={slide.title} />
        </div>
      ));

      // --> makes info control points if slides are more than 2
      if (homeSlides.length > 1) {
        controlItems = homeSlides.map((slide, index) => (
          <div
            key={index}
            className={
              index === activeImg
                ? "home-info-control__item active"
                : "home-info-control__item"
            }
            onClick={() => setActiveImg(index)}
          >
            <div></div>
          </div>
        ));

        homeControl = (
          <div className="home-info__control"> {controlItems} </div>
        );
      }

      /*
      backImgDesc = homeSlides.map((Slide, index) => {
        return (
          <div
            key={index}
            className={index === activeImg ? "home-info-desc__item active" : "home-info-desc__item"}
            onClick={() => setActiveImg(index)}
          >
            <div className={"desc-item__title"}>{Slide.title}</div>
            <div>{Slide.desc}</div>
          </div>
        );
      });
      */

      // console.log(document.documentElement.style);
      if (
        homeSlides[activeImg].colorMode &&
        homeSlides[activeImg].colorMode === "dark"
      ) {
        document.documentElement.style.setProperty(
          "--home-color-filter",
          "invert() saturate(0)"
        );
        document.documentElement.style.setProperty(
          "--home_color-1",
          props.themeColors.themeColorDark1
        );
        document.documentElement.style.setProperty(
          "--home_color-2",
          props.themeColors.themeColorDark2
        );
      } else if (
        !homeSlides[activeImg].colorMode ||
        homeSlides[activeImg].colorMode === "light"
      ) {
        document.documentElement.style.setProperty(
          "--home-color-filter",
          "none"
        );
        document.documentElement.style.setProperty(
          "--home_color-1",
          props.themeColors.themeColor1
        );
        document.documentElement.style.setProperty(
          "--home_color-2",
          props.themeColors.themeColor2
        );
      }
      if (homeSlides[activeImg].Color1) {
        document.documentElement.style.setProperty(
          "--home_color-1",
          homeSlides[activeImg].Color1
        );
      }
      if (homeSlides[activeImg].Color2) {
        document.documentElement.style.setProperty(
          "--home_color-2",
          homeSlides[activeImg].Color2
        );
      }
    }
  }

  useEffect(() => {
    const backgroundInt = setInterval(() => {
      setActiveImg((activeImg) => activeImg + 1);
    }, 5000);
    return () => clearInterval(backgroundInt);
  }, []);

  return (
    <div className="Home">
      <div className="home__background-holder">{backImgItems} </div>
      <div className="home__info">
        <div className="home__info-holder Mas-layout">
          {homeControl}
          {/* <div className="home-info__desc"> {backImgDesc}</div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
