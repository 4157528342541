import React from "react";

import Img from "../../../components/ui/Img";

import "./about.css";

const about = (props) => {
  return (
    <div className="About Mas-layout2">
      <section className="about__brand">
        <div className="about__section__image">
          <Img src="assets/img/BTA Logo.png" />
        </div>
        <div className="about__section__body">
          <div className="about-section__disc">
            <p>
              BTA is an international architecture practice founded by Babak Toosipour in 2016 based in Tehran and
              Melbourne. Babak Toosipour Architecture is a research driven practice focus on sensible integrating
              projects into contexts with enviromental and cultural significance by precise attention to the
              intellectual concept and details underlining sustainable solutions in the essence of innovation.
            </p>
          </div>
        </div>
      </section>

      <section className="about__personal">
        <div className="about__section__image">
          <Img src={`assets/img/Babak Toosipour Portrait.jpg`} alt="Babak Toosipour" />
        </div>
        <div className="about__section__body">
          <div className="about-section__disc">
            <div className="about-section__title">Founder</div>
            <div className="about-section__title">Babak Toosipour</div>
            <p>
              Babak Toosipour was born in Tehran and moved to Australia in 2008. He did his year 12 of school in
              Melbourne and continued his studies in architecture at Royal Melbourne Institute of Technology. He began
              his career as an Architect in Melbourne and continued in Tehran. He founded BTA Company in 2016 under his
              own name. He is a member of Australian Institute of Architects and Silver Medal Winner of architecture in
              Vienna.
            </p>
          </div>
          <div className="about-section__icons">
            <Img src={`assets/img/Honor.png`} alt="RMIT UNI" />
            <Img src={`assets/img/Member SONA.png`} alt="RMIT UNI" />
          </div>
        </div>
      </section>

      <section className="about__awards">
        <div className="about-section__title">Awards</div>
        <div className="about-section__icons">
          <Img src={`assets/img/Award 2A 2016.jpg`} alt="Award 2A 2016" />
          <Img src={`assets/img/Award 2A 2018.jpg`} alt="Award 2A 2018" />
          <Img src={`assets/img/Award IA 1395.jpg`} alt="Award IA 1395" />
          <Img src={`assets/img/Award IMPI 2016.jpg`} alt="Award IMPI 2016" />
        </div>
      </section>

      <section className="about__publication">
        <div className="about-section__title">Publication and News</div>
        <div className="about-section__icons">
          <Img
            src={`assets/img/Publications 2A 2018 Cover.jpg`}
            alt="Publications 2A 2018"
            enlargeable
            enlargedImgSrc={"assets/img/Publications 2A 2018 Inside.jpg"}
          />
          <Img
            src={`assets/img/Publications 2A 2016 Cover.png`}
            alt="Publications 2A 2016"
            enlargeable
            enlargedImgSrc={"assets/img/Publications 2A 2016 Inside.jpg"}
          />
          <Img
            src={`assets/img/Publications AC 1395 Cover.jpg`}
            alt="Publications AC 1395"
            enlargeable
            enlargedImgSrc={"assets/img/Publications AC 1395 Inside.jpg"}
          />
        </div>
        <div className="about-section__icons">
          <Img src={`assets/img/News Tehran Times.jpg`} alt="News Tehran Times" enlargeable />
          <Img src={`assets/img/News Financial Tribune.jpg`} alt="News Financial Tribune" enlargeable />
          <Img src={`assets/img/Publications Architizer 2017.png`} alt="Publications Architizer 2017" enlargeable />
        </div>
      </section>
    </div>
  );
};

export default about;
