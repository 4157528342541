import React, { useState } from "react";

const PracticeItem = (props) => {
  const [expanded, setExpanded] = useState(false);

  let extra = null;
  if (expanded) {
    extra = <div className={expanded ? "descirption desc-expanded" : "descirption"}>{props.children}</div>;
  }
  return (
    <li className="expertise-fields_item" onClick={() => setExpanded(!expanded)}>
      <div className="title"> {props.title} </div>
      {extra}
      <div className="option-btn"> {expanded ? "less" : "more"} </div>
      {/* <div className={expanded? "descirption desc-expanded" : "descirption"}>{props.children}</div> */}
    </li>
  );
};

export default PracticeItem;
