import React, { useState } from "react";

import { BrowserRouter } from "react-router-dom";

// import Projects from "./data/projects";

import App from "./App";

const AppState = (props) => {
  const [requested, setRequested] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [themeColors, setThemeColors] = useState({});
  const [homeSlides, setHomeSlides] = useState([]);
  const [projects, setProjects] = useState({
    projects: [],
    consultancyProjects: [],
  });
  const [contacts, setContacts] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState([]);
  const menuProps = {
    appMenu: {
      active: menuActive,
      toggle: () => setMenuActive(!menuActive),
    },
  };

  if (!requested) {
    // console.log("---");
    /* Ueses data forlder in public folder */
    setRequested(true);
    // fetch("data/projects.json")
    fetch("data/projects-2112.json")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result);
        setProjects({
          projects: result.mainProjects,
          consultancyProjects: result.consultancyProjects,
        });
      })
      .catch((err) => console.log(err));

    fetch("data/configs.json")
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        // console.log(result);
        setThemeColors(result.themeColors);
        setHomeSlides(result.homeSlides);
        setContacts(result.contacts);
        setSocialNetworks(result.socialNetworks);
      })
      .catch((err) => console.log(err));
  }

  const globalProps = {
    themeColors: themeColors,
    homeSlides: homeSlides,
    projects: projects.projects,
    consultancyProjects: projects.consultancyProjects,
    appMenu: {
      active: menuActive,
      toggle: () => setMenuActive(!menuActive),
    },
    contacts: contacts,
    socialNetworks: socialNetworks,
  };

  // console.log("[global props]:",globalProps);

  return (
    <BrowserRouter>
      <App {...globalProps} />
    </BrowserRouter>
  );
};

export default AppState;
