import React from "react";

import HeaderNavItem from "./HeaderNavItem";

const HeaderNav = props => {
  return (
    <nav className="header__nav">
      <HeaderNavItem title={"PROJECTS"} pathname={"/projects"} linkClick={() => props.linkClick()}> 
      {/* <HeaderNavItem title={"PRIVATE"} pathname={"/about"} linkClick={() => props.linkClick()}/>  */}
      {/* <HeaderNavItem title={"CONSULTANCY"} pathname={"/about"} linkClick={() => props.linkClick()}/>  */}
      </HeaderNavItem>
      <HeaderNavItem title={"ABOUT"} pathname={"/about"} linkClick={() => props.linkClick()}/>
      <HeaderNavItem title={"PRACTICE"} pathname={"/practice"} linkClick={() => props.linkClick()}/>
      {/* <HeaderNavItem title={"CONTACT"} pathname={"/contacts"} linkClick={() => props.linkClick()}/> */}
    </nav>
  );
};

export default HeaderNav;
