import React, { useState } from "react";

import "./img.css";

const Img = (props) => {
  const [enlargedImg, setEnlargedImg] = useState(false);
  let cls = props.className ? props.className + " loadingImg" : "loadingImg";

  let clickFn = () => {};
  let popUpImg = null;
  if (props.enlargeable) {
    clickFn = () => setEnlargedImg(true);
  }

  const exit = (Ycord) => {
    document.getElementById("body").classList.remove("nonScroll");
    setEnlargedImg(false);
  };

  if (enlargedImg) {
    let images = (
      <img
        className={cls}
        src={props.enlargedImgSrc || props.src}
        alt={props.alt}
        onLoad={(e) => {
          e.target.classList.remove("loadingImg");
        }}
      />
    );
    // if (props.enlargedImgSrc && Array.isArray(props.enlargedImgSrc)) {
    //   images = props.enlargedImgSrc.map((imgSrc, index) => (
    //     <img
    //     key={index}
    //       className={cls}
    //       src={imgSrc}
    //       alt={props.alt}
    //       onLoad={(e) => {
    //         e.target.classList.remove("loadingImg");
    //       }}
    //     />
    //   ));
    // }
    document.getElementById("body").classList.add("nonScroll");
    popUpImg = (
      <div className="PopUp-img" onClick={() => exit()}>
        {images}
        <div className="option-btn" onClick={() => exit()}>
          close
        </div>
      </div>
    );
  }

  return (
    <>
      <img
        className={cls}
        src={props.src}
        alt={props.alt}
        onLoad={(e) => {
          e.target.classList.remove("loadingImg");
        }}
        onClick={clickFn}
      />
      {popUpImg}
    </>
  );
};

export default Img;
