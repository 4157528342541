import React from "react";
import Img from "../../../components/ui/Img";

import "./contact.css";

const contacts = (props) => {
  // console.log("[contacts:] ", props.contacts);

  const contacts = props.contacts.map((contactItem, index) => (
    <dl className="contact-info__item" key={index}>
      <dt>{contactItem.title}</dt>
      <div className="contact-info-item__item">
        <dt>Phone</dt>
        <dd>
          <span>[{contactItem.phone[0]}] </span>
          {contactItem.phone[1]}
        </dd>
      </div>
      <div className="contact-info-item__item">
        <dt>Email</dt>
        <dd> {contactItem.email}</dd>
      </div>
    </dl>
  ));


  return (
    <div className="Contact Mas-layout2">
      <div className="contact__info">
      {contacts}
        {/* <dl className="contact-info__item">
          <dt>Tehran</dt>
          <div className="contact-info-item__item">
            <dt>Phone</dt>
            <dd>
              <span>[+98] </span>21 36427240
            </dd>
          </div>
          <div className="contact-info-item__item">
            <dt>Email</dt>
            <dd> tehran@toosipour.com</dd>
          </div>
        </dl>

        <dl className="contact-info__item">
          <dt>Melbourne</dt>
          <div className="contact-info-item__item">
            <dt>Phone</dt>
            <dd>
              <span>[+61] </span> 4 22440078
            </dd>
          </div>
          <div className="contact-info-item__item">
            <dt>Email</dt>
            <dd> melbourne@toosipour.com</dd>
          </div>
        </dl> */}
      </div>

      <div className="contact__pic">
        <Img src={`assets/img/Contact Img.jpg`} alt="Contact" />
      </div>
    </div>
  );
};

export default contacts;
